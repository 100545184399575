import { useEffect, useRef, useState } from "react";

export default function Slider({ data }) {
  const [slide, setSlide] = useState(1);
  const scrollRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlide((prev) => {
        if(prev === data.length){
          while(prev > 1){
            return  1
          }
        }
        if(prev < data.length){
          while(prev < data.length){
            return prev + 1
          }
        }
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [data.length]);

  useEffect(() => {
    const scrollToPosition = (target) => {
      const start = scrollRef.current.scrollLeft;
      const change = target - start;
      const duration = 1500; // Duration in ms
      let startTime = null;

      const animateScroll = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1); // Ensure progress doesn't exceed 1
         if(scrollRef.current){
          scrollRef.current.scrollLeft = start + change * progress 
         }

        if (elapsed < duration) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    };

    const targetPosition = (slide - 1) * scrollRef?.current.clientWidth;
    scrollToPosition(targetPosition);
  }, [slide, data.length]);

  const pics = data.map((link, index) => {
    return (
      <img
        className="slideImg"
        key={index}
        src={`${process.env.PUBLIC_URL}${link}`}
        alt={`pic${index + 1}`}
      />
    );
  });

  return (
    <div ref={scrollRef} className="picsWrapper">
      {pics}
    </div>
  );
}