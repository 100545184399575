import { useEffect } from "react";

export default function AboutContact() {
  useEffect(()=>{
    window.scrollTo(0, 1)
  },[])

  return (
    <section className="fullWith aboutContact">
      <span className="aboutH1">
        <h1>
            <b className="boldH1">About Us</b>
        </h1>
      </span>
      <div className="hr"></div>
      <div className="aboutText">
        <span>
          <p className="h2">Address:</p>
          <p>West London</p>
          <p>London, United Kingdom</p>
        </span>
        <span>
          <p className="h3">Contact:</p>
          <p>Phone: +44 7508 826782</p>
          <p>Email address: info@seekprestigeproperties.com</p>
        </span>
        <span>
          <p className="h4">Managing Director:</p>
          <p>Chantall Stoby</p>
        </span>
      </div>
      <div className="hr"></div>
    </section>
  );
}