import AccessBtn from "../accessBtn";
import { chevyLeft, chevyRight } from "./svgs";

export default function DealPackages({current, clickBack, clickMore, data, getAccess=()=>{}}) {
  const packageBox = data.map((item, index) => {
    return (
      index === current - 1 && (
        <div key={index+1} role="presentation" className={`packageWrapper fullWidth`}>
          <h2>{item.name}</h2>
          <div role="presentation" className="allFlex packageDescribeImg">
            <div role="presentation" className="describe">
                <p className="slideinLeft">{item.description}</p>
                <span className="slideinLeft"><AccessBtn getAccess={getAccess}/></span>
            </div>
            <div role="presentation" className='allFlex imgBtn'>
                <img className="dealImg slideInRight" src={item.image} alt={"image" + index} />
                <span className="allFlex imgBtnSpan">
                  {current >1 && <button aria-label='previous Offer' className='dealsBtn' onClick={clickBack}>{chevyLeft}</button>}
                  {current < data.length && <button aria-label='next offer' className='dealsBtn' onClick={clickMore}>{chevyRight}</button>}
                </span>
            </div>
          </div>
        </div>
      )
    );
  });

  return packageBox;
}