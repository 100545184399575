import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function NavBar() {
  const [currPage, setCurrPage] = useState('')
  const [showMenu, setShowMenu] = useState(true)

  useEffect(()=>{
    if(window.location.pathname === '/'){
      return setCurrPage('home')
    }
    if(window.location.pathname === '/about'){
      return setCurrPage('about')
    }
    if(window.location.pathname === '/deals'){
      return setCurrPage('deals')
    }
  },[])

  const theNav = (
    <nav className="nav allFlex">
      <span className="image">
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          className="logo"
          alt="logo"
        />
      </span>
      <div className={showMenu ? "navBtn":"noMenu"}>
        <Link onClick={()=> setCurrPage('home')} className={currPage ==='home'?'navLink activeLink':'navLink'} to="/">Home</Link>
        <Link onClick={()=> setCurrPage('deals')} className={currPage ==='deals'?'navLink activeLink':'navLink'} to="/deals">Our Deals</Link>
        <Link onClick={()=> setCurrPage('about')} className={currPage ==='about'?'navLink activeLink':'navLink'} to="/about">About Us</Link>
        
      </div>
      <section onClick={()=>setShowMenu(!showMenu)} className={showMenu ? 'menuToggle toggledMenu': 'menuToggle'}>
        <div className='menuBars'></div>
        <div className='menuBars'></div>
        <div className='menuBars'></div>
      </section >
    </nav>
  );

  return theNav;
}