import { facebook, instagram, twitter, tiktok } from "./dealsSection/svgs";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="relative fullWidth">
      <div role="presentation">
        <p className="h6">Seek Prestige Properties</p>
        <p className="footerNote">Invest In Value</p>
        <div role="presentation" className="socialLogos">
          {facebook}
          {instagram}
          {twitter}
          {tiktok}
        </div>
        <p className="footerRights">All rights reserved</p>
      </div>
      <div className="allFlex gap rightWrapper">
        <div role="presentation" className="footerLinks">
          <Link onClick={()=>window.scrollTo(0, 0)} className="navLink footerLink" to="/">
            Home
          </Link>
          <Link onClick={()=>window.scrollTo(0, 0)} className="navLink footerLink" to="/deals">
            Our Packages
          </Link>
          <Link onClick={()=>window.scrollTo(0, 0)} className="navLink footerLink" to="/about">
            About Us
          </Link>
        </div>
      </div>
    </footer>
  );
}
