export const arrowLeft = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M20 12H4M4 12L10 6M4 12L10 18"
        stroke="#635FC7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{" "}
    </g>
  </svg>
);
export const arrowRight = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M4 12H20M20 12L14 6M20 12L14 18"
        stroke="#635FC7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{" "}
    </g>
  </svg>
);
export const facebook = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34px"
    height="34px"
    viewBox="0 0 48 48"
    id="facebook"
  >
    <g fill="#ffffff" fillRule="evenodd">
      <g fill="#4460A0" transform="translate(-200 -160)">
        <path
          fill="#ffffff"
          d="M225.638 208H202.65a2.65 2.65 0 0 1-2.649-2.65v-42.7a2.649 2.649 0 0 1 2.65-2.65h42.701a2.649 2.649 0 0 1 2.649 2.65v42.7a2.65 2.65 0 0 1-2.649 2.65h-12.232v-18.588h6.24l.934-7.244h-7.174v-4.625c0-2.098.583-3.527 3.59-3.527l3.836-.002v-6.479c-.663-.088-2.94-.285-5.59-.285-5.53 0-9.317 3.376-9.317 9.575v5.343h-6.255v7.244h6.255V208z"
        ></path>
      </g>
    </g>
  </svg>
);
export const instagram = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#ffffff"
    height="34px"
    width="34px"
    viewBox="0 0 16 16"
    id="instagram"
  >
    <path
      fill="#ffffff"
      d="M5.41421,1.17157c1.17157,1.17157 1.17157,3.07107 0,4.24264c-1.17157,1.17157 -3.07107,1.17157 -4.24264,0c-1.17157,-1.17157 -1.17157,-3.07107 -8.88178e-16,-4.24264c1.17157,-1.17157 3.07107,-1.17157 4.24264,-8.88178e-16"
      transform="translate(4.707 4.707)"
    ></path>
    <path d="M11.5,0h-7c-2.48145,0 -4.5,2.01855 -4.5,4.5v7c0,2.48145 2.01855,4.5 4.5,4.5h7c2.48145,0 4.5,-2.01855 4.5,-4.5v-7c0,-2.48145 -2.01855,-4.5 -4.5,-4.5Zm-3.5,12c-2.20557,0 -4,-1.79395 -4,-4c0,-2.20605 1.79443,-4 4,-4c2.20557,0 4,1.79395 4,4c0,2.20605 -1.79443,4 -4,4Zm4.5,-8c-0.276123,0 -0.5,-0.223877 -0.5,-0.5c0,-0.276184 0.223877,-0.5 0.5,-0.5c0.276123,0 0.5,0.223816 0.5,0.5c0,0.276123 -0.223877,0.5 -0.5,0.5Z"></path>
  </svg>
);
export const twitter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34px"
    height="34px"
    fill="none"
    viewBox="0 0 512 512"
    id="twitter"
  >
    <g clipPath="url(#clip0_84_15697)">
      <rect width="512" height="512" fill="#000" rx="60"></rect>
      <path
        fill="#fff"
        d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_84_15697">
        <rect width="512" height="512" fill="#fff"></rect>
      </clipPath>
    </defs>
  </svg>
);

export const tiktok = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34px"
    height="34px"
    fill="#ffffff"
    viewBox="0 0 24 24"
    id="tik-tok"
  >
    <path
      fill="#ffffff"
      d="M11 1.25C10.5858 1.25 10.25 1.58579 10.25 2V16C10.25 17.2426 9.24264 18.25 8 18.25C6.75736 18.25 5.75 17.2426 5.75 16C5.75 14.7574 6.75736 13.75 8 13.75C8.41421 13.75 8.75 13.4142 8.75 13V10C8.75 9.58579 8.41421 9.25 8 9.25C4.27208 9.25 1.25 12.2721 1.25 16C1.25 19.7279 4.27208 22.75 8 22.75C11.7279 22.75 14.75 19.7279 14.75 16V10.2171C16.2655 11.1872 18.0675 11.75 20 11.75C20.4142 11.75 20.75 11.4142 20.75 11V8C20.75 7.58579 20.4142 7.25 20 7.25C17.1005 7.25 14.75 4.8995 14.75 2C14.75 1.58579 14.4142 1.25 14 1.25H11Z"
    ></path>
  </svg>
);

export const chevyLeft = <svg className="chevies" width="100px" height="100px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Complete"> <g id="F-Chevron"> <polyline fill="none" id="Left" points="15.5 5 8.5 12 15.5 19" stroke="#635FC7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polyline> </g> </g> </g></svg>

export const chevyRight = <svg className="chevies" width="100px" height="100px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Arrow / Chevron_Right"> <path id="Vector" d="M9 5L16 12L9 19" stroke="#635FC7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g></svg>

export const queryMedia = (small, medium, large)=>{
  if(small){
    return 'mobile'
  }
  if(medium){
    return 'tablet'
  }
  if(large){
    return 'desktop'
  }
}