import AccessBtn from "../accessBtn";

export default function HowItWorks({getAccess=()=>{}}) {

  const howHeader = <h3>How It Works...</h3>

  const HowIt = (
    <p className="howItWorksText">
      If you're interested in joining our investors, it's a very straight
      forward process We can send the latest property opportunities in
      London straigth to your inbox. All you have to do is send us an email
      letting us know you want access to our exclusive deals.
    </p>
  );
  return(
    <section id="how" className="howItWorksWrapper allFlex">
        {howHeader}
        {HowIt}
        <AccessBtn getAccess={getAccess}/>
    </section>
  )
}
