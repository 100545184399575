export default function AboutUs() {
  const head = <h2 className="aboutHeader">Why Seek Prestige Properties?</h2>;

  const about = (
    <p className="aboutp1">
      Our expertise stems from decades of experience from our team including one
      of the most successful property experts in the UK, Samuel Leeds. We are fully
      equipped to source great property deals with quality services assured our
      investors.
    </p>
  );

  const about2 = (
    <p className="aboutp2">
      Here at Seek Prestige Properties, we take pride in providing value for our
      clients. We complete all the hard and time consuming tasks to save our
      investors a lot of time. We'll do the researches, book viewings, view properties in
      person and or record the videos for our investors to view if they can't
      make it to the viewings. We also negotiate the prices so all the investors
      have to do is let us know when they're interested in the deals we package.
    </p>
  );

  return (
    <section id="about" className="fullWidth aboutWrapper">
      {head}
      {about}
      {about2}
    </section>
  );
}
