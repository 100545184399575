import { useRef } from "react";
import { arrowLeft, arrowRight } from "./svgs";
import { queryMedia } from "./svgs";
import { useMediaQuery } from "@mui/material";

export default function Thumbnails({ imageClick, data, current }) {
  const slideRef = useRef();
  const small = useMediaQuery("(max-width:768px)")
  const media = queryMedia(small)
  const scrollAmount = media === 'mobile' ? 272: 530

  const theSlidesAdd = () => {
    const newScrollPosition = slideRef.current.scrollLeft + scrollAmount;
    slideRef.current.scrollLeft = Math.min(
      newScrollPosition,
      slideRef.current.scrollWidth - slideRef.current.clientWidth
    );
  };

  const theSlidesMinus = () => {
    const newScrollPosition = slideRef.current.scrollLeft - scrollAmount;
    slideRef.current.scrollLeft = Math.max(newScrollPosition, 0); // Ensure it doesn't go below 0
  };

  const thumbBox = (
    <div ref={slideRef} className="thumbWrapper gap">
      {data.map((item, index) => (
        <span key={index}>
          <img
            onClick={() => imageClick(index+1)} // Pass the item to the imageClick function
            src={item.image}
            alt={"thumb" + index}
            className={`thumb ${current - 1 === index && 'borderBlue'}`}
          />
        </span>
      ))}
    </div>
  );

  return (
    <div className="thumbNailsWrapper">
      <button
        onClick={theSlidesMinus}
        className="arrow"
        aria-label="Scroll left"
      >
        {arrowLeft}
      </button>
      {thumbBox}
      <button
        onClick={theSlidesAdd}
        className="arrow"
        aria-label="Scroll right"
      >
        {arrowRight}
      </button>
    </div>
  );
}