import AccessForm from "./form";

export default function ModalForm({ getAccess = () => { } }) {
    
    return(
        <section className="fullWidth modalSection">
            <section aria-label="modal" onClick={getAccess} className="modal"></section>
            <AccessForm/>
        </section>
    )
}