import { useState, useEffect } from "react";

export default function Testimonies() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("data.json")
      .then((res) => res.json())
      .then((json) => setData(json.testimonies));
  }, []);

  const testimonies = data.map((item, index) => {
    return (
      <div key={index} className="testimonyWrapper">
        <p className="testimony">{item.testimony}</p>
        <p className="nameJob">
          <b>
            {item.name}, {item.occupation}
          </b>
        </p>
      </div>
    );
  });

  return (
    <section className="allFlex testimoniesSection fullWith">
      <h3>Testimonies</h3>
      <hr />
      <div className="innerWrapper">
          {testimonies}
      </div>
    </section>
  );
}
