import { useState } from "react";
import jsonp from "jsonp";

export default function AccessForm() {
  const [info, setInfo] = useState({ FNAME: "", EMAIL: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const url = process.env.REACT_APP_SIGN_UP

  const handleSubmit = (e) => {
    e.preventDefault();
    jsonp(`${url}&EMAIL=${info.EMAIL}&FNAME=${info.FNAME}`)
    setInfo({FNAME:'', EMAIL:''})
  };
  return (
    <form onSubmit={handleSubmit} className="accessForm allFlex">
      <h1>Fill The Form Below</h1>
      <div className="fullWith formFlex">
        <input
          id="name"
          name="FNAME"
          onChange={handleChange}
          value={info.FNAME}
          type="text"
          placeholder="e.g Jerome Michaels"
        />
        <label htmlFor="name">Name</label>
      </div>

      <div className="fullWith formFlex">
        <input
          id="email"
          name="EMAIL"
          onChange={handleChange}
          value={info.EMAIL}
          type="email"
          pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
          placeholder="e.g info@seekprestigeproperties.com"
        />
        <label htmlFor="email">Email</label>
      </div>
      <button type="submit">Get Access</button>
    </form>
  );
}