import AccessBtn from "./accessBtn";

export default function HeaderItems({getAccess=()=>{}}){
    const theOpening = (
        <h1>
          SEEK PRESTIGE PROPERTIES.
          <br />
          We Make It Available When You Are.
        </h1>
      );

    return(
        <article className="opening">
            <div className="h-fit">{theOpening}</div>
            <AccessBtn getAccess={getAccess}/>
        </article>
    )
}