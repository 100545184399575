import NavBar from "./component/navBar";
import Home from "./component/Home";
import Deals from "./component/dealsSection/deals";
import Footer from "./component/footer";
import { Routes, Route } from "react-router";
import { useState } from "react";
import ModalForm from "./component/modalForm/modal";
import AboutContact from "./component/aboutContact";

export default function App() {
  const [showModal, setShowModal] = useState(false)

  const getAccess = ()=>{
    setShowModal(!showModal)
  }  

  return (
    <main className="app relative">
      {showModal && <ModalForm getAccess={getAccess}/>}
      <div className='relative navWrapper'>
        <NavBar />
      </div>
      <Routes>
        <Route path="/" element={<Home getAccess={getAccess}/>}/>
        <Route path="/deals" element={<Deals getAccess={getAccess}/>}/>
        <Route path="/about" element={<AboutContact/>}/>
      </Routes>
      <Footer/>
    </main>
  );
}