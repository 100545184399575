import {useState, useEffect} from 'react'
import Thumbnails from './thumbnails'
import DealPackages from './dealsMainImages'
import HowItWorks from './howItWorks'

export default function Deals({getAccess =()=>{}}){
    const [data, setData] = useState([])
    const [currImg, setCurrImg] = useState(1)

    useEffect(()=>{
        fetch("data.json")
        .then(res => res.json())
        .then(json => setData(json.deals))

        window.scrollTo(0, 1)
    },[])

    const imageClick = (id)=>{
        setCurrImg(id)
    }

    const clickBack = ()=>{
        setCurrImg(prev=> prev ===1? 1:prev - 1)
    }

    const clickMore = ()=>{
        setCurrImg(prev=> prev === data.length? data.length:prev + 1)
    }

    return(
        <section id='deals' className='fullWidth gap allFlex'>
            <div role='presentation' className='dealHeadingWrapper fullWidth'>
                <h1>What We Offer</h1>
                <hr className='dealHR' />
            </div>
            <DealPackages data={data} current={currImg} clickBack={clickBack} clickMore={clickMore} getAccess={getAccess}/>
            <Thumbnails data={data} current={currImg} imageClick={imageClick}/>
            <HowItWorks getAccess={getAccess} purple={true}/>
        </section>
    )
}