import React from "react";
import { useState, useEffect } from "react";
import Slider from "./imageSlider";
import HeaderItems from "./opening";
import AboutUs from "./aboutUs";
import Testimonies from "./testimonies";

export default function Home({getAccess =()=>{}}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("data.json")
      .then((res) => res.json())
      .then((json) => setData(json.homepage));

    window.scrollTo(0,  1)
  }, []);
  
  return (
    <section>
      <header className="relative home" id="home">
        <HeaderItems getAccess={getAccess}/>
        <Slider data={data} />
      </header>
      <AboutUs/>
      <Testimonies/>
    </section>
  );
}